import axios from 'axios'
import i18n from '@/i18n'

class ChartService {
  getCertificatePieData () {
    return axios.get('/api/statistics/certificates/pie').then((result: any) => {
      if (result && result.data && result.data.length) {
        // sort to keep the colors right. if names of cert-levels change, this might change as well
        result.data.sort((a: any, b: any) => a.label > b.label)
        const chart = {
          labels: [] as any[],
          datasets: [
            {
              label: 'Data Label',
              data: [] as any[],
              backgroundColor: ['#4caf50', '#ff7a59', '#db3236', '#3f51b5'],
            },
          ],
        }
        for (const dataset of result.data) {
          chart.labels.push(i18n.t(dataset.label.replace('-', '').toLowerCase()))
          chart.datasets[0].data.push(dataset.value)
        }

        return chart
      }
    })
  }

  getCertificateLineData () {
    return axios.get('/api/statistics/certificates/line').then((result: any) => {
      if (result && result.data) {
        const chart = {
          labels: [] as any[],
          datasets: [
            {
              label: i18n.t('eval1').toString(),
              data: [] as any[],
              fill: false,
              borderColor: '#4caf50',
              tension: 0.4,
            },
          ],
        }
        if (result.data.eval1) {
          for (const dataset of result.data.eval1) {
            chart.labels.push(dataset.label)
            chart.datasets[0].data.push(dataset.value)
          }
        }

        chart.datasets.push({
          label: i18n.t('eval2').toString(),
          data: [],
          fill: false,
          borderColor: '#ff7a59',
          tension: 0.4,
        })
        if (result.data.eval2) {
          for (const dataset of result.data.eval2) {
            chart.datasets[1].data.push(dataset.value)
          }
        }

        chart.datasets.push({
          label: i18n.t('eval3').toString(),
          data: [],
          fill: false,
          borderColor: '#db3236',
          tension: 0.4,
        })
        if (result.data.eval2) {
          for (const dataset of result.data.eval3) {
            chart.datasets[2].data.push(dataset.value)
          }
        }

        chart.datasets.push({
          label: i18n.t('others').toString(),
          data: [],
          fill: false,
          borderColor: '#3f51b5',
          tension: 0.4,
        })
        if (result.data.others) {
          for (const dataset of result.data.others) {
            chart.datasets[3].data.push(dataset.value)
          }
        }
        return chart
      }
    })
  }

  getReportPieData () {
    return axios.get('/api/statistics/reports/pie').then((result: any) => {
      if (result && result.data && result.data.length) {
        // sort to keep the colors right. if names of cert-levels change, this might change as well
        result.data.sort((a: any, b: any) => a.label > b.label)
        const chart = {
          labels: [] as any[],
          datasets: [
            {
              label: 'Data Label',
              data: [] as any[],
              backgroundColor: ['#4caf50', '#db3236', '#ff7a59', '#3f51b5'],
            },
          ],
        }
        for (const dataset of result.data) {
          chart.labels.push(i18n.t(dataset.label.toLowerCase()))
          chart.datasets[0].data.push(dataset.value)
        }

        return chart
      }
    })
  }

  getReportLineData () {
    return axios.get('/api/statistics/reports/line').then((result) => {
      if (result && result.data) {
        const chart = {
          labels: [] as any[],
          datasets: [
            {
              label: i18n.t('freischaltung'),
              data: [] as any[],
              fill: false,
              borderColor: '#4caf50',
              tension: 0.4,
            },
          ],
        }
        for (const dataset of result.data.FREISCHALTUNG) {
          chart.labels.push(dataset.label)
          chart.datasets[0].data.push(dataset.value)
        }

        chart.datasets.push({
          label: i18n.t('isolationswiderstand'),
          data: [],
          fill: false,
          borderColor: '#db3236',
          tension: 0.4,
        })
        for (const dataset of result.data.ISOLATIONSWIDERSTAND) {
          chart.datasets[1].data.push(dataset.value)
        }

        chart.datasets.push({
          label: i18n.t('potentialausgleich'),
          data: [],
          fill: false,
          borderColor: '#ff7a59',
          tension: 0.4,
        })
        for (const dataset of result.data.POTENTIALAUSGLEICH) {
          chart.datasets[2].data.push(dataset.value)
        }

        return chart
      }
    })
  }
}

const chartService = new ChartService()
export default chartService
