<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row justify="center">
      <v-col v-if="certificationAuthority || userIsSystemAdmin" cols="11" md="4">
        <base-material-card
          color="success"
          icon="mdi-chart-pie"
          inline
          class="px-5 py-3"
          :title="$t('dialog.valid-certificates')"
        >
          <div v-if="certificatePieData" class="chart-container">
            <pie-chart :chartdata="certificatePieData" :options="optionsPieChart" style="max-height: 280px" />
          </div>
          <div v-else class="chart-container">
            <p>{{ $t('dialog.no-data-available') }}</p>
          </div>
        </base-material-card>
      </v-col>

      <v-col v-if="certificationAuthority || userIsSystemAdmin" cols="11" md="8">
        <base-material-card
          color="warning"
          icon="mdi-chart-timeline-variant"
          inline
          class="px-5 py-3"
          :title="$t('dialog.certificates-last-30-days')"
        >
          <div v-if="certificateLineData" class="chart-container">
            <line-chart :chartdata="certificateLineData" :options="optionsLineChart" style="max-height: 280px" />
          </div>
          <div v-else class="chart-container">
            <p>{{ $t('dialog.no-data-available') }}</p>
          </div>
        </base-material-card>
      </v-col>
      <v-col v-if="company || !certificationAuthority" cols="11" md="4">
        <base-material-card color="success" icon="mdi-chart-pie" inline class="px-5 py-3" :title="$t('reports')">
          <div v-if="reportPieData" class="chart-container">
            <pie-chart :chartdata="reportPieData" :options="optionsPieChart" style="max-height: 280px" />
          </div>
          <div v-else class="chart-container">
            <p>{{ $t('dialog.no-data-available') }}</p>
          </div>
        </base-material-card>
      </v-col>

      <v-col v-if="company || !certificationAuthority" cols="11" md="8">
        <base-material-card
          color="warning"
          icon="mdi-chart-timeline-variant"
          inline
          class="px-5 py-3"
          :title="$t('dialog.reports-last-30-days')"
        >
          <div v-if="reportLineData" class="chart-container">
            <line-chart :chartdata="reportLineData" :options="optionsLineChart" style="max-height: 280px" />
          </div>
          <div v-else class="chart-container">
            <p>{{ $t('dialog.no-data-available') }}</p>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-if="canCreateReports || userIsSystemAdmin">
      <v-col>
        <h2>{{ $t('newest-topics') }}</h2>
        <ForumTablePreview />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="certificationAuthority || userIsSystemAdmin" cols="12">
        <h2>{{ $t('coworker-education-status') }}</h2>
        <certificates-table dashboard-mode />
      </v-col>
      <v-col v-if="company || userIsSystemAdmin" cols="12">
        <h2>{{ $t('reports') }}</h2>
        <reports-table disable-create-reports />
      </v-col>
    </v-row>
    <v-row v-if="!certificationAuthority && !company" justify="center">
      <v-col cols="10">
        <p>{{ $t('dialog.welcome-to-the-dashboard') }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '@/shared/services/userService'
import { mapGetters } from 'vuex'
import chartService from '@/shared/services/chartService'
import * as Sentry from '@sentry/browser'

export default {
  name: 'DashboardDashboard',

  components: {
    ReportsTable: () => import('./report/ReportsTable.vue'),
    CertificatesTable: () => import('./certificate/CertificatesTable.vue'),
    PieChart: () => import('./PieChart.vue'),
    LineChart: () => import('./LineChart.vue'),
    ForumTablePreview: () => import('./forum/ForumTablePreview.vue'),
  },

  data() {
    return {
      optionsLineChart: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },

      optionsPieChart: {
        responsive: true,
        maintainAspectRatio: false,
      },

      showReportsChart: false,
      showCertificatesChart: false,
      certificatePieData: undefined,
      certificateLineData: undefined,
      reportPieData: undefined,
      reportLineData: undefined,
      reportTableData: undefined,
      canCreateReports: false,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
      certificates: 'certificateStore/getList',
      certificationAuthority: 'selectedCertificationAuthority',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  watch: {
    '$store.getters.selectedCertificationAuthority': {
      async handler() {
        this.getCertificateData()
      },
    },
    '$store.getters.company': {
      async handler() {
        this.getReportData()
      },
    },
  },
  async mounted() {
    userService.checkVersion()
    userService.checkUserProfileIsComplete()

    this.getCertificateData()
    this.getReportData()

    this.setSentryUser()
    this.canCreateReports = await userService.canCreateReports()
  },

  methods: {
    async getCertificateData() {
      if (this.certificationAuthority || this.userIsSystemAdmin) {
        this.certificatePieData = await chartService.getCertificatePieData()
        this.certificateLineData = await chartService.getCertificateLineData()
      }
    },

    async getReportData() {
      if (this.company || this.userIsSystemAdmin) {
        this.reportPieData = await chartService.getReportPieData()
        this.reportLineData = await chartService.getReportLineData()
      }
    },
    setSentryUser() {
      if (this.account._id && this.account.username && this.account.email) {
        Sentry.setUser({ id: this.account._id, username: this.account.username, email: this.account.email })
      }
    },
  },
}
</script>

<style scoped>
.chart-container {
  padding: 10px;
  height: 300px;
}
</style>
